import React from 'react'

export default function Validate() {
  return (
      <svg className='h-6 w-6' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 12.0001L11 14.0001L15 10.0001M7.83474 4.69717C8.55227 4.63991 9.23346 4.35775 9.78132 3.89087C11.0598 2.80135 12.9402 2.80135 14.2187 3.89087C14.7665 4.35775 15.4477 4.63991 16.1653 4.69717C17.8397 4.83079 19.1693 6.16043 19.303 7.83486C19.3602 8.55239 19.6424 9.23358 20.1093 9.78144C21.1988 11.0599 21.1988 12.9403 20.1093 14.2188C19.6424 14.7667 19.3602 15.4479 19.303 16.1654C19.1693 17.8398 17.8397 19.1695 16.1653 19.3031C15.4477 19.3603 14.7665 19.6425 14.2187 20.1094C12.9402 21.1989 11.0598 21.1989 9.78132 20.1094C9.23346 19.6425 8.55227 19.3603 7.83474 19.3031C6.16031 19.1695 4.83067 17.8398 4.69705 16.1654C4.63979 15.4478 4.35763 14.7667 3.89075 14.2188C2.80123 12.9403 2.80123 11.0599 3.89075 9.78144C4.35763 9.23358 4.63979 8.55239 4.69705 7.83486C4.83067 6.16043 6.16031 4.83079 7.83474 4.69717Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

  )
}
