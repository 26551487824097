import axios from 'axios';
import { getApiBaseUrl, getAccessToken } from '../config';

export async function getCliente(idCliente) {
    return getJson(`/documents/client-info/${idCliente}`);
}

export async function getDocumentos(idCliente) {
    const json = await getJson(`/documents/get-by-client/${idCliente}`);

    return json.documents;
}

export async function getTiposDocumentos() {
    const json = await getJson(`/documents/types`);

    return json.types;
}

export async function removeDocumento(id) {
    return await deleteJson(`/documents/${id}`);
}

export async function validarDocumento(id, type, description = '') {
    const form = {
        id: id,
        type: type,
        description: description,
    };

    return postJson(`/documents/validate`, form)
}

export async function uploadDocumento(clientId, type, file, description = '', notify = false, documentIds = [], filename = '', onProgress) {
    const form = {
        client_id: clientId,
        notify: notify ? 1 : 0,
        document_ids: documentIds.join(','),
        description: description,
    };

    if (parseInt(type) > 0) {
        form.type = type;
    }

    const data = formatPostBody(form)
    data.append('file', file, filename !== '' ? filename : file.name)

    const config = {
        onUploadProgress: onProgress
    }

    const client = getClient(config);
    const response = await client.post(`/documents/upload`, data);

    return response.data;
}

export async function sendShortlink(clientId, viaEmail, viaSms, generateNew, emailMessage, smsMessage) {
    const form = {
        client_id:  clientId,
        via_email:  viaEmail ? '1' : '0',
        via_sms:  viaSms ? '1' : '0',
        generate_new:  generateNew,
        email_message:  emailMessage,
        sms_message:  smsMessage,
    }

    return postJson(`/documents/send-shortlink`, form)
}

function getClient(config = {}) {
    const def = {
        baseURL: getApiBaseUrl(),
        headers: {
            'Authorization': `Bearer ${getAccessToken()}`,
        }
    };

    return axios.create({...def, ...config});
}

async function getJson(endpoint) {
    const client = getClient();
    const response = await client.get(endpoint);

    return response.data;
}

async function deleteJson(endpoint) {
    const client = getClient();
    const response = await client.delete(endpoint);

    return response.data;
}

async function postJson(endpoint, form = {}, config = {}) {
    const client = getClient(config);
    const response = await client.post(endpoint, formatPostBody(form));

    return response.data;
}

function formatPostBody(form) {
    const data = new FormData();

    for (const key in form) {
        if (Object.hasOwnProperty.call(form, key)) {
            data.append(key, form[key]);
        }
    }

    return data;
}
