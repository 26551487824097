import React from 'react'
import { useDrag } from 'react-dnd'
import Document from './Document'

export default function DraggableDocument({ document, onClick, onDoubleClick, isSelected } ) {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'document',
        item:  { id: document.id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    })
    return (
        <div ref={dragRef}>
            <Document
                document={document}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                isSelected={isSelected}
                isDragging={isDragging}
            />
        </div>
  )
}
