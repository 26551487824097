import React from 'react'

export default function MainTitle({ title, children }) {
    return (
        <div className='pb-4 border-b border-[#DBDBDB] mb-6'>
            <div className='flex justify-between items-end'>
                <h1 className="font-normal text-4xl">{title}</h1>
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}
