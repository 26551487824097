import React from 'react'
import { Button, Modal } from 'react-daisyui'

export default function ConfirmPopup({ open, onClose, onConfirm, title, children }) {
    return (
        <Modal open={open} onClickBackdrop={onClose}>
            <Modal.Header>{title}</Modal.Header>

            <Modal.Body>
                {children}
            </Modal.Body>

            <Modal.Actions>
                <Button color="ghost" onClick={onClose}>Cancelar</Button>
                <Button onClick={onConfirm}>
                    Aceptar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
