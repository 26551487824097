import React from 'react'

export default function Msg() {
  return (
      <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 19.0001V10.0705C3 9.40177 3.3342 8.77731 3.8906 8.40637L10.8906 3.73971C11.5624 3.29184 12.4376 3.29184 13.1094 3.73971L20.1094 8.40637C20.6658 8.77731 21 9.40177 21 10.0705V19.0001M3 19.0001C3 20.1047 3.89543 21.0001 5 21.0001H19C20.1046 21.0001 21 20.1047 21 19.0001M3 19.0001L9.75 14.5001M21 19.0001L14.25 14.5001M3 10.0001L9.75 14.5001M21 10.0001L14.25 14.5001M14.25 14.5001L13.1094 15.2605C12.4376 15.7084 11.5624 15.7084 10.8906 15.2605L9.75 14.5001" stroke="#2A2E37" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

  )
}
