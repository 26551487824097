import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import ListadoDocumentos from './components/ListadoDocumentos';
import {useQuery} from "react-query";
import {getCliente, getDocumentos, getTiposDocumentos} from "./services/api";
import RevisionDocumentos from "./components/RevisionDocumentos";
import NuevoDocumento from './components/NuevoDocumento';
import { ToastContainer } from 'react-toastify';
import FusionarDocumentos from './components/FusionarDocumentos';
import OrganizarDocumentos from './components/OrganizarDocumentos';
import { Button } from 'react-daisyui';
import clsx from 'clsx';

export default function App() {
    const { idCliente } = useParams();
    const resultadoCliente = useQuery('appClient', () => getCliente(idCliente));
    const resultadoDocumentos = useQuery('appDocuments', () => getDocumentos(idCliente));
    const resultadoTiposDocumentos = useQuery('appDocumentTypes', () => getTiposDocumentos());

    return (
        <div>
            <div className={clsx('transition-opacity duration-300 delay-100', { 'opacity-0': resultadoCliente.isLoading || resultadoDocumentos.isLoading || resultadoTiposDocumentos.isLoading })}>
                <Routes>
                    <Route path="/" element={<ListadoDocumentos data={{ cliente: resultadoCliente.data, documentos: resultadoDocumentos.data, tipos: resultadoTiposDocumentos.data } } />}/>
                    <Route path="/revisar" element={<RevisionDocumentos data={{ cliente: resultadoCliente.data, documentos: resultadoDocumentos.data, tipos: resultadoTiposDocumentos.data } } />}/>
                    <Route path="/nuevo/fusionar" element={<FusionarDocumentos data={{ cliente: resultadoCliente.data, documentos: resultadoDocumentos.data, tipos: resultadoTiposDocumentos.data }} />} />
                    <Route path="/nuevo/organizar" element={<OrganizarDocumentos data={{ cliente: resultadoCliente.data, documentos: resultadoDocumentos.data, tipos: resultadoTiposDocumentos.data }} />} />
                    <Route path="/nuevo" element={<NuevoDocumento data={{ cliente: resultadoCliente.data, tipos: resultadoTiposDocumentos.data }} />} />
                </Routes>
            </div>
            <div className={clsx('fixed inset-0 flex justify-center items-center h-screen', { 'hidden': !resultadoCliente.isLoading && !resultadoDocumentos.isLoading && !resultadoTiposDocumentos.isLoading })}>
                <Button className='loading' color="ghost" size='lg'></Button>
            </div>
            <ToastContainer
                autoClose={3000}
            />
        </div>
    );
}
