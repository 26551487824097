import clsx from 'clsx';
import React, { useState } from 'react'
import { Button, Modal } from 'react-daisyui'
import { removeDocumento } from '../services/api';

export default function RemoveSelectedDocumentsPopup({ open, onClose, onConfirm, documentIds }) {
    const [loading, setLoading] = useState(false)
    const ok = async () => {
        if (!loading) {
            setLoading(true);
            const promises = documentIds.map(id => removeDocumento(id))
            await Promise.all(promises)
            onConfirm();
            setLoading(false);
        }
    }
    const close = () => {
        if (!loading) {
            onClose();
        }
    }

    return (
        <Modal open={open} onClickBackdrop={close}>
            <Modal.Header>{documentIds.length === 1 ? `Eliminar documento` : `Eliminar documentos`}</Modal.Header>

            <Modal.Body>
                ¿Estás seguro de que deseas eliminar&nbsp;
                <span className="font-bold">
                    {documentIds.length === 1 ? `${documentIds.length} documento seleccionado` : `${documentIds.length} documentos seleccionados`}
                </span>? Esta acción no se podrá deshacer
            </Modal.Body>

            <Modal.Actions>
                <Button color="ghost" onClick={close}>Cancelar</Button>
                <Button className={clsx({ loading: loading})} onClick={ok} color="error">
                    Confirmar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
