import clsx from 'clsx';
import Info from '../icons/Info';
import moment from 'moment';
import React, { useState } from 'react'
import { Alert, Button, Modal, Textarea, Toggle } from 'react-daisyui'
import { sendShortlink } from '../services/api';

export default function SendShortlinkPopup({ open, onClose, onConfirm, client }) {
    const DEFAULT_MESSAGE = 'Ya puedes enviarnos documentación, pulsa en el siguiente enlace para subir documentación relativa al accidente a tu expediente.'
    const [resendLoading, setResendLoading] = useState(false)
    const [sendLoading, setSendLoading] = useState(false)
    const [emailChecked, setEmailChecked] = useState(false)
    const [phoneChecked, setPhoneChecked] = useState(false)
    const [emailMessage, setEmailMessage] = useState(DEFAULT_MESSAGE)
    const [smsMessage, setSmsMessage] = useState(DEFAULT_MESSAGE)
    const send = async () => {
        if (!sendLoading && !resendLoading) {
            setSendLoading(true)
            await sendShortlink(client.id, emailChecked, phoneChecked, 1, emailMessage, smsMessage);
            onConfirm();
            setSendLoading(false)
        }
    }
    const resend = async () => {
        if (!sendLoading && !resendLoading) {
            setResendLoading(true)
            await sendShortlink(client.id, emailChecked, phoneChecked, 0, emailMessage, smsMessage);
            onConfirm();
            setResendLoading(false)
        }
    }
    const close = () => {
        if (!sendLoading && !resendLoading) {
            onClose();
        }
    }

    return (
        <Modal className="max-w-6xl p-10" open={open} onClickBackdrop={close}>

            <Modal.Body>
                <h2 className="text-center text-2xl mt-5">Enviar shortlink</h2>
                {client && client.shortlink_url ? (
                    <Alert className="mt-16 w-3/4 mx-auto" status="info" icon={<Info/>}>
                        El usuario ya tiene un shortlink creado <a className="font-bold" target="_blank" rel="noreferrer" href={client.shortlink_url}>{client.shortlink_url}</a> . Expirará el {moment(client.shortlink_expiration_date).format('D MMM HH:mm')}
                    </Alert>
                ) : ''}
                <div className='mt-20 flex w-5/6 mx-auto space-x-24 mb-12'>
                    <div className="w-1/2">
                        { client && client.email ? (
                            <div>
                                <div className="flex items-center mb-6">
                                    <Toggle color="primary" onChange={() => setEmailChecked(!emailChecked)} checked={emailChecked} />
                                    <span className="ml-3">Enviar por email a {client.email}</span>
                                </div>
                                {emailChecked ? (
                                    <Textarea
                                        value={emailMessage}
                                        onChange={e => setEmailMessage(e.target.value)}
                                        className="w-full h-40" bordered></Textarea>
                                ) : ''}
                            </div>

                        ) : '' }
                    </div>
                    <div className="w-1/2">
                        {client && client.phone ? (
                            <div>
                                <div className="flex items-center mb-6">
                                    <Toggle color="primary" onChange={() => setPhoneChecked(!phoneChecked)} checked={phoneChecked} />
                                    <span className="ml-3">Enviar por SMS a {client.phone}</span>
                                </div>
                                {phoneChecked ? (
                                    <Textarea
                                        value={smsMessage}
                                        onChange={e => setSmsMessage(e.target.value)}
                                        className="w-full h-40" bordered></Textarea>
                                ) : ''}
                            </div>

                        ) : ''}
                    </div>
                </div>
            </Modal.Body>

            <Modal.Actions className="">
                <Button color="ghost" onClick={close}>Cancelar</Button>
                <Button variant="outline" className={clsx({ loading: resendLoading })} onClick={resend}>
                    Reenviar
                </Button>
                <Button className={clsx({ loading: sendLoading})} onClick={send}>
                    Enviar nuevo
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
