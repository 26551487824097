import clsx from 'clsx'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { validateFiles } from '../utils/validation';
import { getValidExtensions } from '../config';

export default function UploadFileInput( { onFilesReady, dropZone = true, validExtensions = [] } ) {
    const [dropAreaActive, setDropAreaActive] = useState(false)
    const inputExtensions = validExtensions && validExtensions.length > 0 ? validExtensions : getValidExtensions();
    const onSelectFiles = e => {
        if (!validateFileField(e.target)) {
            return;
        }

        onFilesReady(e.target.files);
    }
    const activeDropArea = (e) => {
        if (dropZone) {
            setDropAreaActive(true)
        }
    }
    const inactiveDropArea = (e) => {
        if (dropZone) {
            setDropAreaActive(false)
        }
    }
    const validateFileField = elem => {
        const files = elem.files;

        if (files.length === 0) {
            return false;
        }

        const [valid, errorMessage] = validateFiles(files, inputExtensions);

        if (!valid) {
            toast.error(errorMessage);
            elem.value = '';
        }

        return valid;
    }

    return (
        <div onDragOver={e => e.stopPropagation()}>
            <div className={clsx('absolute top-0 left-0 w-full h-screen bg-[#570DF8B2]', { invisible: !dropAreaActive })} />
            <input
                onChange={onSelectFiles}
                onDragOver={activeDropArea}
                onDragLeave={inactiveDropArea}
                onDrop={inactiveDropArea}
                type="file"
                multiple
                className='absolute top-0 left-0 w-full h-screen opacity-0'
                accept={inputExtensions.join(',')}
            />
        </div>
  )
}
