import ExternalLink from '../icons/ExternalLink';
import React from 'react';
import { Button } from 'react-daisyui';
import { Link } from 'react-router-dom';
import { openWindow } from '../utils/browser';

export default function DefaultLayout({ client, children }) {
    return (
        <>
            <div className="h-14 px-8 bg-neutral flex items-center justify-start">
                <Link to={`/documentacion/${client ? client.id : 0}`}>
                    <span className='mr-4 text-white font-bold text-xl'>{client ? `${client.name} (ID: ${client.code})` : ''}</span>
                </Link>
                <Button onClick={() => openWindow(client.profile_url)} target="_blank" startIcon={<ExternalLink/>} color="secondary" variant='outline' size="sm">Ficha</Button>
            </div>
            <div className="px-8 py-7">
                { children }
            </div>
        </>
    );
}
