const config = {
    apiBaseUrl: '',
    accessToken: '',
    maxFileSize: 12288, // In Kb
    validExtensions: ['.jpg', '.jpeg', '.png', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.msg', '.jfif'],
};

export function getApiBaseUrl() {
    config.apiBaseUrl = localStorage.getItem('apiBaseUrl');

    return config.apiBaseUrl;
}
export function setApiBaseUrl(url) {
    config.apiBaseUrl = url;
    localStorage.setItem('apiBaseUrl', config.apiBaseUrl);
}

export function getAccessToken() {
    config.accessToken = localStorage.getItem('accessToken');

    return config.accessToken;
}
export function setAccessToken(url) {
    config.accessToken = url;
    localStorage.setItem('accessToken', config.accessToken);
}

export function getMaxFileSize() {
    return config.maxFileSize;
}

export function getValidExtensions() {
    return config.validExtensions
}
