import clsx from 'clsx';
import React, { useState } from 'react'
import { Button, Modal } from 'react-daisyui'
import { validarDocumento } from '../services/api';

export default function ValidateSelectedDocumentsPopup({ open, onClose, onConfirm, documentIds, documentTypes = [] }) {
    const [loading, setLoading] = useState(false)
    const [documentType, setDocumentType] = useState('0')
    const ok = async () => {
        if (!loading) {
            setLoading(true);
            const promises = documentIds.map(id => validarDocumento(id, documentType))
            await Promise.all(promises)
            onConfirm();
            setLoading(false);
        }
    }
    const close = () => {
        if (!loading) {
            onClose();
        }
    }

    return (
        <Modal open={open} onClickBackdrop={close}>
            <Modal.Header>{documentIds.length === 1 ? `Validar documento` : `Validar documentos`}</Modal.Header>

            <Modal.Body>
                <p>Vas a validar&nbsp;
                    <span className="font-bold">
                        {documentIds.length === 1 ? `${documentIds.length} documento seleccionado` : `${documentIds.length} documentos seleccionados`}
                    </span>.
                    <br/>Indica el tipo de documento
                </p>
                <select onChange={e => setDocumentType(e.target.value)} className="select select-bordered w-full mt-4 mb-10">
                    <option value="0">-- Selecciona --</option>

                    { documentTypes.map(type => (
                        <option key={type.id} value={type.id}>{type.name}</option>
                    ))}

                </select>
            </Modal.Body>

            <Modal.Actions>
                <Button color="ghost" onClick={close}>Cancelar</Button>
                <Button disabled={documentType === '0'} className={clsx({ loading: loading})} onClick={ok}>
                    Confirmar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
