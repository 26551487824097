import clsx from 'clsx';
import RemoveWhite from '../icons/RemoveWhite';
import Rotate from '../icons/Rotate';
import React from 'react'
import { Button } from 'react-daisyui';
import { useDrag, useDrop } from 'react-dnd';

// const DocumentPage = React.forwardRef(({ page, index, movePage }, ref) => {
const DocumentPage = React.forwardRef(({ page, index, movePage, onPreview, onRotate, onRemove }, ref) => {
    const [{ handlerId }, drop] = useDrop({
        accept: 'page',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get horizontal middle
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the left
            const hoverClientX = clientOffset.x - hoverBoundingRect.left
            // Only perform the move when the mouse has crossed half of the items width
            // When dragging right, only move when the cursor is below 50%
            // When dragging left, only move when the cursor is above 50%
            if (dragIndex < hoverIndex && (hoverClientX < hoverMiddleX)) {
                return
            }

            if (dragIndex > hoverIndex && (hoverClientX > hoverMiddleX)) {
                return
            }
            // Time to actually perform the action
            movePage(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: 'page',
        item: () => {
            return { id: page.id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    drag(drop(ref))

    return page.removed ? '' : (
        <div
            className={clsx(`w-24 mx-5 mb-10 select-none`, { 'opacity-0': isDragging })}
            ref={ref}
            data-handler-id={handlerId}
            onDoubleClick={() => onPreview(page)}
        >
            <div className='w-24 h-32 relative'>
                <canvas className={clsx('!w-full mx-auto max-w-[6rem] !h-auto max-h-[8rem] rounded-lg drop-shadow-md transition-opacity duration-300 origin-center', { 'opacity-0': !page.ready, 'translate-y-5 max-h-[6rem] !w-auto': page.rotation === 90 || page.rotation === 270 , 'rotate-90': page.rotation === 90, 'rotate-180': page.rotation === 180, 'rotate-[270deg]': page.rotation === 270 })}>

                </canvas>
                <div className={clsx('absolute inset-0 flex items-center drop-shadow-md rounded-lg bg-white justify-center transition-opacity duration-300', { 'opacity-0': page.ready })}>
                    <Button className='loading' size="lg" color="ghost"></Button>
                </div>
                <div className={clsx('absolute p-3 inset-0 flex items-end justify-between bg-gray-500 opacity-0 rounded-lg transition-opacity hover:opacity-90')}>
                    <div onClick={() => onRotate(index)}>
                        <Rotate />
                    </div>
                    <div onClick={() => onRemove(index) }>
                        <RemoveWhite />
                    </div>
                </div>
            </div>
            <div className="text-xs mt-3 break-all">{page.document.filename}</div>
            <div className="text-xs mt-1 text-gray-400">Pág. {page.current} de {page.total}</div>
        </div>
    );
});

export default DocumentPage
