import NewDocument from '../icons/NewDocument';
import React, { useEffect, useState } from 'react'
import BreadCrumbs from './BreadCrumbs';
import DefaultLayout from './DefaultLayout';
import MainTitle from './MainTitle';
import { Button, RadialProgress } from 'react-daisyui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Folder from '../icons/Folder';
import clsx from 'clsx';
import { PDFDocument } from 'pdf-lib';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf.js'
import DocumentPage from './DocumentPage';
import PreviewPagePopup from './PreviewPagePopup';
import ConfirmPopup from './ConfirmPopup';
import SaveDocumentPopup from './SaveDocumentPopup';
import { useQueryClient } from 'react-query';
import Help from '../icons/Help';
import HelpPopup from './HelpPopup';

export default function OrganizarDocumentos({ data }) {
    const { cliente, tipos } = data;
    const [searchParams] = useSearchParams();
    const documentIds = (searchParams.get("ids") || '').split(',').map(id => parseInt(id)).filter(id => !isNaN(id));
    const isNewDocument = searchParams.get("new") && searchParams.get("new").length > 0 ? true : false
    const [loading, setLoading] = useState(true)
    const [fetching, setFetching] = useState(false)
    const [rendering, setRendering] = useState(false)
    const [progress, setProgress] = useState(0)
    const [pages, setPages] = useState([])
    const [pdfDoc, setPdfDoc] = useState(null)
    const [selectedPage, setSelectedPage] = useState(null)
    const [previewPopupVisible, setPreviewPopupVisible] = useState(false)
    const [backPopupVisible, setBackPopupVisible] = useState(false)
    const [savePopupVisible, setSavePopupVisible] = useState(false)
    const [firstDocument, setFirstDocument] = useState(null)
    const [helpPopupVisible, setHelpPopupVisible] = useState(false)
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    useEffect(() => {
        if (!fetching && loading && data && data.documentos) {
            pdfjs.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.js';
            setFetching(true)
        }
    }, [data])
    useEffect(() => {
        if (fetching) {
            const documents = documentIds.map(id => data.documentos.find(doc => doc.id === id)).filter(doc => !!doc)

            if (documents.length === 0) {
                return;
            }

            if (!isNewDocument) {
                setFirstDocument(documents[0])
            }

            loadDocuments(documents)
        }
    }, [fetching])
    useEffect(() => {
        if (pdfDoc && !rendering) {
            setRendering(true);
            renderDocuments()
        }
    }, [pages])
    const loadDocuments = async documents => {
        const ps = [];
        const pdf = await PDFDocument.create()
        let globalIndex = 0;

        for (let j = 0; j < documents.length; j++) {
            const doc = documents[j];
            const res = await fetch(doc.url)
            const buffer = await res.arrayBuffer()
            const p = await PDFDocument.load(buffer)
            const copiedPages = await pdf.copyPages(p, p.getPageIndices())

            for (let index = 0; index < copiedPages.length; index++) {
                ps.push({
                    id: `${doc.id}-${index + 1}`,
                    document: doc,
                    current: index + 1,
                    total: copiedPages.length,
                    ready: false,
                    removed: false,
                    rotation: 0,
                    internalRotation: copiedPages[index].getRotation().angle,
                    ref: React.createRef(),
                    index: globalIndex
                });
                pdf.addPage(copiedPages[index]);
                globalIndex++
            }

            setProgress(Math.min(100, Math.round(((j + 1) * 100) / documents.length)))
        }

        setPages([...pages, ...ps]);
        setPdfDoc(pdf)
    }
    const renderDocuments = async () => {
        const pdfBytes = await pdfDoc.save();
        const loadingTask = pdfjs.getDocument(pdfBytes);

        const pdfObj = await loadingTask.promise;
        setLoading(false)
        setFetching(false)

        for (let index = 0; index < pdfObj.numPages; index++) {
            const page = await pdfObj.getPage(index + 1);
            const scale = 1;
            const viewport = page.getViewport({ scale: scale });
            const canvas = pages[index].ref.current.querySelector('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            var renderContext = {
                canvasContext: context,
                viewport: viewport
            };
            const renderTask = page.render(renderContext);
            renderTask.promise.then(_ => {
                const newPages = pages.slice();
                newPages[index].ready = true;
                setPages(newPages);
            })
        }

        return true;
    }
    const movePage = (dragIndex, hoverIndex) => {
        const newPages = pages.slice();
        const page = newPages[dragIndex];
        newPages.splice(dragIndex, 1)
        newPages.splice(hoverIndex, 0, page)
        setPages(newPages)

    };
    const previewPage = page => {
        setSelectedPage(page)
        setPreviewPopupVisible(true)
    }
    const rotatePage = index => {
        const newPages = pages.slice();
        newPages[index].rotation += 90;
        newPages[index].internalRotation += 90;

        if (newPages[index].rotation >= 360) {
            newPages[index].rotation = 0;
        }

        if (newPages[index].internalRotation >= 360) {
            newPages[index].internalRotation = 0;
        }

        setPages(newPages);
    }
    const removePage = index => {
        const newPages = pages.slice();
        newPages[index].removed = true;
        setPages(newPages);
    }
    const navigateBack = () => {
        navigate(`../nuevo/fusionar?new=${isNewDocument ? 'true' : ''}&ids=${documentIds.join(',')}`);
    }
    const onConfirmSave = () => {
        setSavePopupVisible(false)
        queryClient.invalidateQueries('appDocuments');
        navigate(`../`)
    }

    return (
        <DefaultLayout client={cliente}>
            <BreadCrumbs client={cliente} items={[
                { label: isNewDocument ? 'Nuevo documento' : 'Organizar documentos', url: null, icon: isNewDocument ? <NewDocument size="small" /> : <Folder /> }
            ]} />

            <MainTitle title="Organizar">
                <Button
                    startIcon={<Help />}
                    className="mr-6"
                    onClick={() => setHelpPopupVisible(true)}
                    color="ghost">
                    Ayuda
                </Button>
            </MainTitle>

            <div className='p-10 h-[65vh] overflow-y-scroll relative bg-slate-200'>
                <div className={clsx('transition-opacity duration-300', { 'opacity-0': loading })}>
                    <div className='mt-16 max-w-6xl mx-auto flex flex-wrap justify-center'>
                        {pages.map((page, i) => (
                            <DocumentPage
                                ref={pages[i].ref}
                                page={page}
                                movePage={movePage}
                                index={i}
                                key={page.id}
                                onPreview={previewPage}
                                onRotate={rotatePage}
                                onRemove={removePage}
                            />
                        ))}
                    </div>
                </div>
                <div className={clsx('absolute inset-0 flex justify-center items-center transition-opacity duration-300', { 'hidden': !loading })}>
                    <RadialProgress size="10rem" thickness="1rem" value={progress}><span className="text-3xl">{progress}%</span></RadialProgress>
                </div>
            </div>

            <div className='flex justify-between mt-8'>
                <Button
                    variant='outline'
                    color="primary"
                    onClick={() => setBackPopupVisible(true)}
                    >
                    Volver y añadir más documentos
                </Button>


                <Button
                    disabled={loading}
                    color="primary"
                    onClick={() => setSavePopupVisible(true)}
                >
                    Guardar
                </Button>
            </div>

            <PreviewPagePopup
                ref={selectedPage ? selectedPage.ref : null}
                page={selectedPage}
                open={previewPopupVisible}
                onClose={() => setPreviewPopupVisible(false)}
            />

            <ConfirmPopup
                open={backPopupVisible}
                onClose={() => setBackPopupVisible(false)}
                onConfirm={navigateBack}
                title="Volver y añadir más documentos"
            >
                <div>¿Estás seguro de que desea volver? Los cambios que has realizado en las páginas se perderán.</div>
            </ConfirmPopup>

            <SaveDocumentPopup
                open={savePopupVisible}
                onClose={() => setSavePopupVisible(false)}
                onConfirm={onConfirmSave}
                pdfDoc={pdfDoc}
                pages={pages}
                clientId={cliente ? cliente.id : null}
                filename={firstDocument ? firstDocument.filename : ''}
                type={firstDocument ? firstDocument.type_id : 0}
                initialDescription={firstDocument ? firstDocument.description : ''}
                documentIds={documentIds}
                documentTypes={tipos}
            />

            <HelpPopup
                open={helpPopupVisible}
                onClose={() => setHelpPopupVisible(false)}
            >
                <p className="mb-3"><strong>Arrastra</strong> las páginas para <strong>reordenarlas</strong>.</p>
                <p className="mb-3">Pon el <strong>cursor encima</strong> de una página para <strong>rotar o eliminarla</strong>.</p>
                <p className="mb-6">Puedes hacer <strong>doble click</strong> en las páginas para verlas <strong>a pantalla completa</strong>.</p>
            </HelpPopup>

        </DefaultLayout>
    )
}
