import React from 'react'

export default function Rotate() {
  return (
      <svg className='w-6 h-6' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="5.38931" y="14.9247" width="9.27802" height="9.27802" rx="2" transform="rotate(-45 5.38931 14.9247)" stroke="white" strokeWidth="2" />
          <path d="M3.11725 3.7435L3.11747 7.18721L3.97107 7.18722M19.4371 7.9785C18.6375 4.08284 15.0423 1.30215 10.9752 1.62163C7.62815 1.88454 4.92461 4.17145 3.97107 7.18722M3.97107 7.18722L6 7.18721" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

  )
}
