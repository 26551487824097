import AttachButton from '../icons/AttachButton';
import MergeDocuments from '../icons/MergeDocuments';
import NewDocument from '../icons/NewDocument';
import Upload from '../icons/Upload';
import React, { useState } from 'react'
import BreadCrumbs from './BreadCrumbs';
import DefaultLayout from './DefaultLayout';
import MainTitle from './MainTitle';

import { toast } from 'react-toastify';
import UploadDocumentsPopup from './UploadDocumentsPopup';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import UploadFileInput from './UploadFileInput';

export default function NuevoDocumento( { data } ) {
    const { cliente, tipos } = data;
    const queryClient = useQueryClient();
    const [uploadPopupVisible, setUploadPopupVisible] = useState(false)
    const [files, setFiles] = useState([])
    const navigate = useNavigate()
    const onFilesReady = files => {
        setFiles(files);
        openUploadPopup();
    }
    const openUploadPopup = () => {
        setUploadPopupVisible(true)
    }
    const closeUploadPopup = () => {
        setUploadPopupVisible(false)
    }
    const onConfirmUpload = stay => {
        setFiles([]);
        closeUploadPopup();
        queryClient.invalidateQueries('appDocuments');
        toast.success("Los documentos se han guardado correctamente");

        if (!stay) {
            navigate(`/documentacion/${cliente.id}`);
        }
    }

    return (
        <DefaultLayout client={cliente}>
            <BreadCrumbs client={cliente} items={[
                { label: 'Nuevo documento', url: null, icon: <NewDocument size="small" /> }
            ]} />

            <MainTitle title="Elige el origen del documento"> </MainTitle>

            <div className='bg-slate-100 py-9 px-16'>
                <div className='flex justify-center max-w-6xl mx-auto'>
                    <div className='bg-white rounded-2xl drop-shadow-lg p-16 relative overflow-hidden flex items-center justify-center w-2/5'>
                        <div className='flex flex-col text-center justify-center items-center'>
                            <Upload/>
                            <h2 className='mt-10 mb-4 font-bold text-xl'>Subir ficheros desde mi ordenador</h2>
                            <p className='mb-8'>Arrastra a este cuadro los archivos o haz click en el botón</p>
                            <AttachButton/>
                        </div>
                        <UploadFileInput
                            onFilesReady={onFilesReady}
                        />
                    </div>
                    <div className='flex flex-col w-36'>
                        <div className='w-px border-l border-gray-400 grow self-center'></div>
                        <div className='text-center p-8 text-sm'>O bien</div>
                        <div className='w-px border-l border-gray-400 grow self-center'></div>

                    </div>
                    <div className='bg-white rounded-2xl drop-shadow-lg p-16 relative overflow-hidden flex items-center justify-center w-2/5'>
                        <div className='flex flex-col text-center justify-center items-center'>
                            <MergeDocuments />
                            <h2 className='mt-10 mb-4 font-bold text-xl'>Fusionar documentos</h2>
                            <p className='mb-8'>Crea un nuevo documento a partir de ficheros o documentos existentes</p>
                            <Link to="fusionar?new=true">
                                <div className='absolute top-0 left-0 w-full h-screen opacity-0'></div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <UploadDocumentsPopup
                client={cliente}
                files={files}
                open={uploadPopupVisible}
                onClose={closeUploadPopup}
                onConfirm={onConfirmUpload}
                documentTypes={tipos}
            />

        </DefaultLayout>
    )
}
