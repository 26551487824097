import NewDocument from '../icons/NewDocument';
import React, { useEffect, useState } from 'react'
import BreadCrumbs from './BreadCrumbs';
import DefaultLayout from './DefaultLayout';
import MainTitle from './MainTitle';
import { Button } from 'react-daisyui';
import clsx from 'clsx';
import * as documentos from "../accessors/documents";
import DocumentosAgrupados from './DocumentosAgrupados';
import { useDrop } from 'react-dnd';
import BlankDocument from '../icons/BlankDocument';
import Close from '../icons/Close';
import AttachButton from '../icons/AttachButton';
import UploadFileInput from './UploadFileInput';
import UploadDocumentsPopup from './UploadDocumentsPopup';
import { useQueryClient } from 'react-query';
import { NativeTypes } from 'react-dnd-html5-backend'
import Upload from '../icons/Upload';
import { validateFiles } from '../utils/validation';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Folder from '../icons/Folder';
import HelpPopup from './HelpPopup';
import Help from '../icons/Help';
import { openWindow } from '../utils/browser';

export default function FusionarDocumentos( { data } ) {
    const { cliente, tipos } = data;
    const [searchParams] = useSearchParams();
    const documentIds = (searchParams.get("ids") || '').split(',').map(id => parseInt(id)).filter(id => !isNaN(id));
    const isNewDocument = searchParams.get("new") && searchParams.get("new").length > 0 ? true : false
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [tabValidatedOpen, setTabValidatedOpen] = useState(false)
    const [pendientesAgrupados, setPendientesAgrupados] = useState({})
    const [validadosAgrupados, setValidadosAgrupados] = useState({})
    const [fusionables, setFusionables] = useState(data && data.documentos ? data.documentos.filter(doc => documentIds.indexOf(doc.id) >= 0) : [])
    const [uploadPopupVisible, setUploadPopupVisible] = useState(false)
    const [helpPopupVisible, setHelpPopupVisible] = useState(false)
    const [files, setFiles] = useState([])
    useEffect(() => {
        setPendientesAgrupados(documentos.agruparPorFecha(documentos.soloPdf(documentos.sinValidar(data).filter(doc => fusionables.findIndex(d => d.id === doc.id) < 0))));
        setValidadosAgrupados(documentos.agruparPorFecha(documentos.soloPdf(documentos.listado(data).filter(doc => fusionables.findIndex(d => d.id === doc.id) < 0))));
    }, [data])
    const [{ isOver }, dropRef] = useDrop({
        accept: ['document', NativeTypes.FILE],
        drop: ( {id, files} ) => {
            if (id) {
                markFusionableById(id)
            }
            else if (files && files.length > 0) {
                const [valid, errorMessage] = validateFiles(files, ['.pdf']);

                if (!valid) {
                    toast.error(errorMessage);
                }
                else {
                    onFilesReady(files);
                }
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    })
    const previewDocument = docuemnt => {
        openWindow(docuemnt.preview_url)
    }
    const markFusionableById = id => {
        setPendientesAgrupados(documentos.eliminarAgrupadoPorId(pendientesAgrupados, id));
        setValidadosAgrupados(documentos.eliminarAgrupadoPorId(validadosAgrupados, id));
        const doc = data.documentos.find(doc => doc.id === id);

        if (doc && fusionables.findIndex(doc => doc.id === id) < 0) {
            setFusionables([...fusionables, doc])
        }
    }
    const removeFusionables = document => {
        setFusionables(fusionables.filter(doc => doc.id !== document.id))

        if (document.validated) {
            const docs = documentos.deagrupar(validadosAgrupados)
            docs.push(document);
            setValidadosAgrupados(documentos.agruparPorFecha(docs));
        }
        else {
            const docs = documentos.deagrupar(pendientesAgrupados)
            docs.push(document);
            setPendientesAgrupados(documentos.agruparPorFecha(docs));
        }
    }
    const onFilesReady = files => {
        setFiles(files);
        openUploadPopup();
    }
    const openUploadPopup = () => {
        setUploadPopupVisible(true)
    }
    const closeUploadPopup = () => {
        setUploadPopupVisible(false)
    }
    const onConfirmUpload = async (stay, docs = []) => {
        setFiles([]);
        closeUploadPopup();
        setFusionables([...fusionables, ...docs])
        queryClient.invalidateQueries('appDocuments');
    }
    const next = () => {
        if (fusionables.length > 0) {
            navigate(`../nuevo/organizar?ids=${fusionables.map(doc => doc.id).join(',')}&new=${isNewDocument ? 'true' : ''}`)
        }
    }

    return (
        <DefaultLayout client={cliente}>
            <BreadCrumbs client={cliente} items={[
                { label: isNewDocument ? 'Nuevo documento' : 'Organizar documentos', url: null, icon: documentIds.length === 0 ? <NewDocument size="small" /> : <Folder /> }
            ]} />

            <MainTitle title="Selecciona los documentos que quieres fusionar">
                <Button
                    startIcon={<Help />}
                    className="mr-6"
                    onClick={() => setHelpPopupVisible(true)}
                    color="ghost">
                    Ayuda
                </Button>
            </MainTitle>

            <div className='flex space-x-16'>
                <div className='w-1/2 drop-shadow-lg rounded-xl p-9 h-[65vh] overflow-hidden bg-slate-100'>
                    <h2 className='font-bold mb-5 text-xl'>Documentación del expediente</h2>
                    <div className="tabs pb-12">
                        <div onClick={() => setTabValidatedOpen(false)} className={clsx('tab tab-bordered grow', { 'tab-active': !tabValidatedOpen })}>Pendientes de revisión</div>
                        <div onClick={() => setTabValidatedOpen(true)} className={clsx('tab tab-bordered grow', { 'tab-active': tabValidatedOpen })}>Otros documentos del expediente</div>
                    </div>
                    <div className={clsx({hidden: tabValidatedOpen})}>
                        <DocumentosAgrupados
                            draggable
                            documents={pendientesAgrupados}
                            size="small"
                            onClick={() => false}
                            onDoubleClick={previewDocument}
                            isSelected={() => false}
                        />
                    </div>
                    <div className={clsx({hidden: !tabValidatedOpen})}>
                        <DocumentosAgrupados
                            draggable
                            documents={validadosAgrupados}
                            size="small"
                            onClick={() => false}
                            onDoubleClick={previewDocument}
                            isSelected={() => false}
                        />
                    </div>
                </div>
            <div className={clsx('w-1/2 relative drop-shadow-lg rounded-xl h-[65vh] overflow-hidden bg-white')} ref={dropRef}>
                    <div className={clsx('absolute top-0 left-0 w-full h-screen z-50 bg-[#570DF8B2]', { invisible: !isOver })} />
                    <div className={clsx('flex flex-col justify-between')}>
                    <div className={clsx('p-9 h-[50vh] border-b border-gray-400')}  > { /* */}
                            <h2 className='font-bold mb-6 text-xl'>Documentos que se fusionarán</h2>
                            <div className="flex flex-wrap place-content-start overflow-y-scroll h-[34vh] ">
                            {fusionables.map((document, i) => (
                                    <div key={i} className='flex mb-4 mr-14 items-center justify-between w-80'>
                                        <div
                                            className='flex items-center select-none'
                                            onDoubleClick={() => previewDocument(document)}
                                        >
                                            <div className='mr-2'>
                                                <BlankDocument/>
                                            </div>
                                            <div className='text-sm w-64 h-4 text-ellipsis overflow-hidden'>{document.filename}</div>
                                        </div>
                                        <div className='mt-1'>
                                            <Button
                                                className='px-1 ml-2'
                                                size="xs"
                                                startIcon={<Close/>}
                                                color="ghost"
                                                onClick={() => removeFusionables(document)}
                                            >
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='p-9 h-[15vh] relative overflow-hidden flex items-center justify-center'>
                            <div className='flex justify-center items-center'>
                                <Upload />
                                <p className='w-2/5 ml-12 mr-10 text-sm'>Arrastra los archivos en formato PDF de tu ordenador aquí</p>
                                <AttachButton />
                            </div>
                            <UploadFileInput
                                onFilesReady={onFilesReady}
                                dropZone={false}
                                validExtensions={['.pdf']}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-end mt-8 relative'>
                <Button
                    onClick={next}
                    disabled={fusionables.length === 0}
                    color="primary">
                    Siguiente
                </Button>
            </div>

            <UploadDocumentsPopup
                validated={false}
                client={cliente}
                files={files}
                open={uploadPopupVisible}
                onClose={closeUploadPopup}
                onConfirm={onConfirmUpload}
                documentTypes={tipos}
            />

            <HelpPopup
                open={helpPopupVisible}
                onClose={() => setHelpPopupVisible(false)}
                    >
                <p className="mb-3"><strong>Arrastra</strong> los documentos desde la ventana de la izquierda o desde tu PC <strong>hacia la ventana de la derecha</strong>.</p>
                <p className="mb-3">Puedes hacer <strong>doble click</strong> en los documentos para <strong>previsualizar el contenido</strong>.</p>
                <p className="mb-6">Solo aprecen los documentos que tienen formato PDF.</p>
                <p className="mb-6">Los documentos que ya se han usado para crear otros documentos se muestran con el <span className="line-through opacity-50">nombre tachado</span>.</p>

            </HelpPopup>

        </DefaultLayout>
    )
}
