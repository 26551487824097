import clsx from 'clsx'
import React from 'react'

export default function NewDocument({ size = 'normal' }) {
  return (
      <svg className={clsx({ 'w-4': size === 'small', 'h-4': size === 'small', 'w-6': size !== 'small', 'h-6': size !== 'small' }, 'mr-2 stroke-current')} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 13H15M12 10L12 16M17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H12.5858C12.851 3 13.1054 3.10536 13.2929 3.29289L18.7071 8.70711C18.8946 8.89464 19 9.149 19 9.41421V19C19 20.1046 18.1046 21 17 21Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
  )
}
