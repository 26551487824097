import clsx from 'clsx'
import React from 'react'
import Document from './Document'
import DraggableDocument from './DraggableDocument'

export default function DocumentosAgrupados({ documents, onClick, onDoubleClick, isSelected, size = 'normal', draggable = false }) {
    return (
      <div className={clsx('overflow-y-scroll', { 'h-[65vh]': size !== 'small', 'h-[43vh]': size === 'small' })}>
          {Object.keys(documents).map(key => (
              <div key={key} className='flex flex-nowrap'>
                  <div
                    className={clsx('shrink-0 font-bold text-gray-400 mb-5', size !== 'small' && 'w-32 mr-14', size === 'small' && 'w-24 mr-10 text-sm')}
                    >
                        Recibidos<br />{key}
                </div>
                  <div className='flex flex-wrap'>
                      {documents[key].map(document => (
                          <div key={document.id} className={clsx(size !== 'small' && 'mr-14 mb-14', size === 'small' && 'mr-10 mb-10')}>
                            { draggable ? (
                                <DraggableDocument
                                      onClick={onClick}
                                      onDoubleClick={onDoubleClick}
                                      document={document}
                                      isSelected={isSelected}
                                  />

                            ) : (
                                <Document
                                      onClick={onClick}
                                      onDoubleClick={onDoubleClick}
                                      document={document}
                                      isSelected={isSelected}
                                  />
                            )}

                        </div>
                      ))}
                  </div>
              </div>
          ))}
        </div>
  )
}
