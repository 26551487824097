import React, { useState } from 'react';
import DefaultLayout from "./DefaultLayout";
import Breadcrumbs from "./BreadCrumbs";
import * as documentos from "../accessors/documents";
import { Link, useNavigate } from "react-router-dom";
import MainTitle from './MainTitle';
import { Button } from 'react-daisyui';
import Anchor from '../icons/Anchor';
import NewDocument from '../icons/NewDocument';
import Download from '../icons/Download';
import Edit from '../icons/Edit';
import Remove from '../icons/Remove';
import RemoveDocumentPopup from './RemoveDocumentPopup';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import SendShortlinkPopup from './SendShortlinkPopup';
import InfoBlue from '../icons/InfoBlue';
import EditDocumentTypePopup from './EditDocumentTypePopup';
import Preview from '../icons/Preview';
import clsx from 'clsx';
import { openWindow } from '../utils/browser';

function ListadoDocumentos({ data }) {
    const { cliente, tipos } = data;
    const queryClient = useQueryClient();
    const documentosListado = documentos.listado(data);
    const pendientesRevision = documentos.sinValidar(data).filter(document => !document.used);
    const [removePopupVisible, setRemovePopupVisible] = useState(false);
    const [popupDocumentId, setPopupDocumentId] = useState(0);
    const [popupDocumentFilename, setPopupDocumentFilename] = useState('');
    const [popupDocumentTypeId, setPopupDocumentTypeId] = useState(0)
    const [popupDescription, setPopupDescription] = useState('')
    const [shortlinkPopupVisible, setShortlinkPopupVisible] = useState(false)
    const [editTypePopupVisible, setEditTypePopupVisible] = useState(false)
    const navigate = useNavigate();
    const openRemovePopup = (id, filename) => {
        setPopupDocumentId(id)
        setPopupDocumentFilename(filename)
        setRemovePopupVisible(true)
    }
    const closeRemovePopup = () => {
        setRemovePopupVisible(false)
    }
    const onConfirmRemove = () => {
        closeRemovePopup();
        queryClient.invalidateQueries('appDocuments');
        toast.success("El documento se ha eliminado correctamente");
    }
    const closeShortlinkPopup = () => {
        setShortlinkPopupVisible(false)
    }
    const onConfirmShortlink = () => {
        closeShortlinkPopup();
        queryClient.invalidateQueries('appClient');
        toast.success("El shortlink se ha enviado correctamente");
    }
    const editDocument = (documentId, filename, typeId, description) => {
        const extension = filename.split('.').pop();

        if (extension.toLowerCase() === 'pdf') {
            navigate(`nuevo/organizar?ids=${documentId}`)
        }
        else {
            setPopupDocumentId(documentId)
            setPopupDocumentFilename(filename)
            setPopupDocumentTypeId(typeId)
            setPopupDescription(description)
            setEditTypePopupVisible(true)
        }
    }
    const closeEditTypePopup = () => {
        setEditTypePopupVisible(false)
    }
    const onConfirmEditType = () => {
        closeEditTypePopup();
        queryClient.invalidateQueries('appDocuments');
        toast.success("El documento se ha editado correctamente");
    }

    return (
        <DefaultLayout client={cliente}>
            <Breadcrumbs client={cliente} items={[]} />

            <MainTitle title="Documentación">
                <Button
                    startIcon={<Anchor/>}
                    className="mr-6"
                    onClick={() => setShortlinkPopupVisible(true)}
                    color="primary">
                    Enviar shortlink
                </Button>
                <Link to="nuevo">
                    <Button
                        startIcon={<NewDocument/>}
                        color="primary">
                        Nuevo documento
                    </Button>
                </Link>
            </MainTitle>

            { pendientesRevision.length ? (
                <div className="alert shadow-lg mx-auto max-w-3xl mb-6">
                    <div>
                        <InfoBlue/>
                        <span>{pendientesRevision.length === 1 ? `Hay ${pendientesRevision.length} documento pendiente de revision` : `Hay ${pendientesRevision.length} documentos pendientes de revision` }</span>
                    </div>
                    <div className="flex-none">
                        <Link to="revisar">
                            <button className="btn btn-sm btn-accent text-white">Revisar ahora</button>
                        </Link>
                    </div>
                </div>
            ) : '' }

            {
                pendientesRevision.length === 0 && documentosListado.length === 0 ? (
                   <p className="text-lg mt-8">No hay documentos</p>
                ) : ''
            }

            <div className='overflow-x-auto'>
                <table className="table w-full mt-5">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Archivo</th>
                            <th>Tipo</th>
                            <th>Subido por</th>
                            <th>Observaciones</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>

                    <tbody>
                        {documentosListado.map(({ id, preview_url, download_url, created_at, filename, type_id, type_name, uploaded_by, description, processed, used }) => (
                        <tr key={id} className={clsx( { 'opacity-50': used})}>
                            <td>{moment(created_at).format('DD/MM/YYYY')}</td>
                            <td className='!whitespace-normal break-all'>
                                { processed ? (
                                    <a href={preview_url} target="_blank" rel="noreferrer" className="font-bold">{filename}</a>
                                ) : (
                                    <span className='text-gray-400'>{filename}</span>
                                ) }

                            </td>
                            <td className='!whitespace-normal'>{type_name}</td>
                            <td>{uploaded_by}</td>
                            <td className='!whitespace-normal'>
                                {description}
                            </td>
                            <td>
                                { processed ? (
                                    <span>
                                        <Button
                                            className="p-2"
                                            startIcon={<Preview/>}
                                            color="ghost"
                                            onClick={() => openWindow(preview_url)}>
                                        </Button>
                                        <Button
                                            className="p-2"
                                            startIcon={<Download/>}
                                            color="ghost"
                                            href={download_url}>
                                        </Button>
                                    </span>
                                ) : '' }
                                <Button
                                    className="p-2"
                                    startIcon={<Edit/>}
                                    color="ghost"
                                    onClick={() => editDocument(id, filename, type_id, description)}>
                                </Button>
                                <Button
                                    className="p-2"
                                    startIcon={<Remove/>}
                                    color="ghost"
                                    onClick={() => openRemovePopup(id, filename)}>
                                </Button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <RemoveDocumentPopup
                documentId={popupDocumentId}
                filename={popupDocumentFilename}
                open={removePopupVisible}
                onClose={closeRemovePopup}
                onConfirm={onConfirmRemove} />

            <SendShortlinkPopup
                open={shortlinkPopupVisible}
                client={cliente}
                onClose={closeShortlinkPopup}
                onConfirm={onConfirmShortlink}
            />

            <EditDocumentTypePopup
                documentId={popupDocumentId}
                filename={popupDocumentFilename}
                type={popupDocumentTypeId}
                initialDescription={popupDescription}
                documentTypes={tipos}
                open={editTypePopupVisible}
                onClose={closeEditTypePopup}
                onConfirm={onConfirmEditType} />

        </DefaultLayout>
    );
}

export default ListadoDocumentos;
