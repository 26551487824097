import clsx from 'clsx';
import Doc from '../icons/Doc';
import Pdf from '../icons/Pdf';
import Msg from '../icons/Msg';
import Xls from '../icons/Xls';
import React, { useState } from 'react'
import { Button } from 'react-daisyui';

export default function StaticThumbnail ({ document }) {
    const extension = document.filename.split('.').pop();
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const onRenderPdf = () => {
        setPdfLoaded(true)
    }

    return (
        <div className="w-24 select-none">
            <div className='w-24 h-32'>
                {extension.toLowerCase() === 'jpg' ? (
                    <div className='relative w-24 h-32'>
                        <div className={clsx('transition-opacity duration-300', { 'opacity-0': !pdfLoaded })}>
                            <div className="stack">
                                <img
                                    className={clsx(`!w-full !h-auto max-h-[8rem] drop-shadow-md rounded-lg `)}
                                    alt={document.filename}
                                    src={document.thumbnail_url}
                                    onLoad={onRenderPdf}
                                />
                            </div>
                        </div>
                        <div className={clsx('absolute inset-0 flex items-center drop-shadow-md rounded-lg bg-white justify-center transition-opacity duration-300', {'opacity-0': pdfLoaded})}>
                            <Button className='loading' size="lg" color="ghost"></Button>
                        </div>
                    </div>
                ) : (
                    <div className='h-32 rounded-lg drop-shadow-md bg-white flex items-center justify-center'>
                        { extension === 'doc' || extension === 'docx' ? (<Doc/>) : (
                            extension === 'xls' || extension === 'xlsx' ? (<Xls/>) : (
                                extension === 'msg' ? (<Msg/>) : (
                                    extension === 'pdf' ? (<Pdf/>) : '')
                            )
                        )}
                    </div>
                )}
            </div>
            <div className={clsx('text-xs mt-3 break-all', document.used && 'line-through')}>{document.filename}</div>
            <div className="text-xs text-gray-400">
                {document.page_count > 0 ? (document.page_count === 1 ? '1 página' : document.page_count + ' páginas') : '' }&nbsp;
            </div>
        </div>
    )
}

