import clsx from 'clsx';
import React, { useState } from 'react'
import { Button, Modal } from 'react-daisyui'
import { removeDocumento } from '../services/api';

export default function RemoveDocumentPopup({ open, onClose, onConfirm, documentId, filename }) {
    const [loading, setLoading] = useState(false)
    const ok = async () => {
        if (!loading) {
            setLoading(true);
            await removeDocumento(documentId);
            onConfirm();
            setLoading(false);
        }
    }
    const close = () => {
        if (!loading) {
            onClose();
        }
    }

    return (
        <Modal open={open} onClickBackdrop={close}>
            <Modal.Header>Eliminar documento</Modal.Header>

            <Modal.Body>
                ¿Estás seguro de que deseas eliminar <span className="font-bold">{filename}</span>? Esta acción no se podrá deshacer

            </Modal.Body>

            <Modal.Actions>
                <Button color="ghost" onClick={close}>Cancelar</Button>
                <Button className={clsx({ loading: loading})} onClick={ok} color="error">
                    Confirmar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
