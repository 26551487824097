import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-daisyui'

const PreviewPagePopup = React.forwardRef(({ open, onClose, page }, ref) => {
    const [src, setSrc] = useState(null)
    const close = () => {
        onClose();
    }
    useEffect(() => {
        if (page && ref) {
            const origin = ref.current.querySelector('canvas')
            setSrc(origin.toDataURL("image/png"));
        }
    }, [page, ref])

    return (
        <Modal open={open} onClickBackdrop={close} className="max-w-4xl">
            <Modal.Body>
                <div className="relative">
                    { src ? (
                        <img src={src} alt={page.document.filename} className={clsx('block mx-auto w-full !h-auto origin-center', {'!w-auto': page.rotation === 90 || page.rotation === 270, 'rotate-90': page.rotation === 90, 'rotate-180': page.rotation === 180, 'rotate-[270deg]': page.rotation === 270 })} />
                    ) : ''}
                </div>
            </Modal.Body>
        </Modal>
    )
})

export default PreviewPagePopup
