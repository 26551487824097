import clsx from 'clsx'
import React from 'react'
import StaticThumbnail from './StaticThumbnail'

export default function Document({ document, onClick, onDoubleClick, isSelected, isDragging = false } ) {
    return (
        <div
            onClick={() => onClick(document)}
            onDoubleClick={() => onDoubleClick(document)}
            className={clsx('p-3', isSelected(document) && 'bg-purple-100 font-bold text-purple-700', (isDragging || document.used) && 'opacity-50')}>
            <StaticThumbnail document={document} />
        </div>
  )
}
