import clsx from 'clsx';
import React, { useEffect, useState } from 'react'
import { Button, Modal, Textarea } from 'react-daisyui'
import { validarDocumento } from '../services/api';

export default function EditDocumentTypePopup({ open, onClose, onConfirm, documentId, filename, type, initialDescription = '', documentTypes = [] }) {
    const [loading, setLoading] = useState(false)
    const [documentType, setDocumentType] = useState(0)
    const [description, setDescription] = useState('')
    useEffect(() => {
      setDocumentType(type)
    }, [type])
    useEffect(() => {
        setDescription(initialDescription)
    }, [initialDescription])

    const ok = async () => {
        if (!loading) {
            setLoading(true);
            await validarDocumento(documentId, documentType, description)
            onConfirm();
            setLoading(false);
        }
    }
    const close = () => {
        if (!loading) {
            onClose();
        }
    }

    return (
        <Modal open={open} onClickBackdrop={close}>
            <Modal.Header>{filename}</Modal.Header>

            <Modal.Body>
                <p>Indica el tipo de documento</p>
                <select onChange={e => setDocumentType(parseInt(e.target.value))} value={documentType} className="select select-bordered w-full mt-4 mb-10">
                    <option value="0">-- Selecciona --</option>

                    { documentTypes.map(type => (
                        <option key={type.id} value={type.id}>{type.name}</option>
                    ))}

                </select>
                <p>Observaciones (opcional)</p>
                <Textarea
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    className="w-full h-28 mb-10 mt-4" bordered></Textarea>
            </Modal.Body>

            <Modal.Actions>
                <Button color="ghost" onClick={close}>Cancelar</Button>
                <Button disabled={documentType === 0} className={clsx({ loading: loading})} onClick={ok}>
                    Confirmar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
