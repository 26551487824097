import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import Home from './Home';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
        <DndProvider debugMode={true} backend={HTML5Backend}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/documentacion/:idCliente/*" element={<App/>} />
                </Routes>
            </BrowserRouter>
        </QueryClientProvider>
    </DndProvider>
</React.StrictMode>
);
