import EditWhite from '../icons/EditWhite';
import Folder from '../icons/Folder';
import InfoPurple from '../icons/InfoPurple';
import RemoveWhite from '../icons/RemoveWhite';
import Validate from '../icons/Validate';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as documentos from "../accessors/documents";
import Breadcrumbs from "./BreadCrumbs";
import DefaultLayout from "./DefaultLayout";
import DocumentosAgrupados from './DocumentosAgrupados';
import MainTitle from './MainTitle';
import RemoveSelectedDocumentsPopup from './RemoveSelectedDocumentsPopup';
import ValidateSelectedDocumentsPopup from './ValidateSelectedDocumentsPopup';
import { Link } from 'react-router-dom';
import { Button } from 'react-daisyui';
import Help from '../icons/Help';
import HelpPopup from './HelpPopup';
import { openWindow } from '../utils/browser';

export default function RevisionDocumentos({ data }) {
    const { cliente, tipos } = data;
    const [selectedDocuments, setSelectedDocuments] = useState([])
    const [removePopupVisible, setRemovePopupVisible] = useState(false);
    const [validatePopupVisible, setValidatePopupVisible] = useState(false);
    const [helpPopupVisible, setHelpPopupVisible] = useState(false)
    const queryClient = useQueryClient();
    const pendientesRevision = documentos.sinValidar(data);
    const pendientesAgrupados = documentos.agruparPorFecha(pendientesRevision);
    const previewDocument = docuemnt => {
        openWindow(docuemnt.preview_url)
    }
    const selectDocument = document => {
        if (!isSelected(document)) {
            setSelectedDocuments([...selectedDocuments, document.id])
        }
        else {
            setSelectedDocuments(selectedDocuments.filter(d => d !== document.id))
        }
    }
    const isSelected = document => {
        return selectedDocuments.indexOf(document.id) >= 0
    }
    const openRemovePopup = () => {
        setRemovePopupVisible(true)
    }
    const closeRemovePopup = () => {
        setRemovePopupVisible(false)
    }
    const onConfirmRemove = () => {
        setSelectedDocuments([]);
        closeRemovePopup();
        queryClient.invalidateQueries('appDocuments');
        toast.success("Los documentos se han eliminado correctamente");
    }
    const openValidatePopup = () => {
        setValidatePopupVisible(true)
    }
    const closeValidatePopup = () => {
        setValidatePopupVisible(false)
    }
    const onConfirmValidate = () => {
        setSelectedDocuments([]);
        closeValidatePopup();
        queryClient.invalidateQueries('appDocuments');
        toast.success("Los documentos se han validado correctamente");
    }
    const canSelectedEdit = () => {
        return selectedDocuments.length === data.documentos.filter(doc => selectedDocuments.findIndex(id => doc.id === id) >= 0 && doc.filename.split('.').pop().toLowerCase() === 'pdf').length
    }

    return (
        <DefaultLayout client={cliente}>

            <Breadcrumbs client={cliente} items={[
                {label: 'Revisar documentación', url: null, icon: <Folder/>}
            ]} />

            <MainTitle title="Documentación remitida por el cliente">
                <Button
                    startIcon={<Help />}
                    className="mr-6"
                    onClick={() => setHelpPopupVisible(true)}
                    color="ghost">
                    Ayuda
                </Button>
            </MainTitle>

            { selectedDocuments.length > 0 ? (
                <div className="alert bg-purple-100 shadow-lg mx-auto">
                    <div>
                        <InfoPurple />
                        <span className="text-purple-700">
                            {selectedDocuments.length === 1 ? `${selectedDocuments.length} documento seleccionado` : `${selectedDocuments.length} documentos seleccionados`}
                        </span>
                    </div>
                    <div className="flex-none">
                        <button onClick={openValidatePopup} className="btn btn-sm btn-primary text-white">
                            <Validate/>
                            <span className="ml-1">Validar</span>
                        </button>
                        {canSelectedEdit() ? (
                            <Link to={`../nuevo/fusionar?new=true&ids=${selectedDocuments.join(',')}`} className="flex">
                                <button className="btn btn-sm btn-primary text-white">
                                    <EditWhite />
                                    <span className="ml-1">Organizar</span>
                                </button>
                            </Link>
                        ) : '' }
                        <button onClick={openRemovePopup} className="btn btn-sm btn-primary text-white">
                            <RemoveWhite />
                            <span className="ml-1">Eliminar</span>
                        </button>
                    </div>
                </div>
            ) : ''}

            { pendientesRevision.length === 0 ? (
                <p className="text-lg mt-8">No hay documentos</p>
            ) : (
               <div className='bg-slate-100 py-9 pl-16'>
                    <DocumentosAgrupados
                        documents={pendientesAgrupados}
                        onClick={selectDocument}
                        onDoubleClick={previewDocument}
                        isSelected={isSelected}
                    />
                </div>
            ) }

            <RemoveSelectedDocumentsPopup
                documentIds={selectedDocuments}
                open={removePopupVisible}
                onClose={closeRemovePopup}
                onConfirm={onConfirmRemove} />

            <ValidateSelectedDocumentsPopup
                documentIds={selectedDocuments}
                open={validatePopupVisible}
                onClose={closeValidatePopup}
                onConfirm={onConfirmValidate}
                documentTypes={tipos}
            />

            <HelpPopup
                open={helpPopupVisible}
                onClose={() => setHelpPopupVisible(false)}
                >
                <p className="mb-3">Haz <strong>click</strong> sobre el documento para <strong>seleccionarlo</strong>.</p>
                <p className="mb-3">Una vez tengas documentos seleccionados, podrás:</p>
                <ul className='list-disc ml-5'>
                    <li><strong>Validar</strong>: Te permitirá seleccionar una categoría para añadirlos al expediente</li>
                    <li><strong>Organizar</strong>: Combinar las páginas, rotarlas, ordenarlas y añadirlas a otros documentos existentes</li>
                    <li><strong>Eliminar</strong>: Rechazarlos definitivamente</li>
                </ul>
                <p className="my-3">También puedes hacer <strong>doble click</strong> en los documentos para <strong>previsualizar el contenido</strong>.</p>
                <p className="mb-6">Solo se pueden organizar documentos que tengan formato PDF.</p>
                <p className="mb-6">Los documentos que ya se han usado para crear otros documentos se muestran con el <span className="line-through opacity-50">nombre tachado</span>.</p>

            </HelpPopup>


        </DefaultLayout>

    );
}
