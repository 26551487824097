import React, { useEffect } from 'react';
import { setApiBaseUrl, setAccessToken } from './config';
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Home() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    
    useEffect(() => {
        if (!searchParams.get("apiBaseUrl") || !searchParams.get("accessToken")) {
            throw new Error('Parámetros incorrectos');
        }

        setApiBaseUrl(searchParams.get("apiBaseUrl"));
        setAccessToken(searchParams.get("accessToken"));    
        navigate(`/documentacion/${searchParams.get("clientId")}`);        
    });

    return (
        <></>
    );
}
