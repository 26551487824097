import React from 'react'

export default function Pdf() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">

   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
   fill="#000000" stroke="none">
   <path d="M879 5106 c-42 -15 -81 -43 -111 -81 -49 -62 -48 -38 -48 -1297 l0
   -1178 -113 0 c-78 0 -128 -5 -161 -16 -64 -22 -147 -100 -177 -166 l-24 -53 0
   -800 0 -800 24 -50 c32 -63 92 -124 156 -155 l50 -25 1315 0 c1264 0 1316 1
   1350 19 49 26 88 64 113 111 22 40 22 47 25 629 2 394 -1 606 -8 641 -15 70
   -59 128 -122 159 l-52 26 -1270 0 c-897 0 -1283 3 -1311 11 -113 32 -161 172
   -88 259 42 51 71 60 188 60 l105 0 0 -49 c0 -90 75 -136 130 -81 20 20 20 33
   20 1331 l0 1311 29 29 29 29 1461 0 1460 0 3 -392 c3 -388 3 -393 25 -424 45
   -63 51 -64 472 -64 l381 0 0 -1941 0 -1941 -29 -29 -29 -29 -1872 0 -1872 0
   -29 29 c-19 20 -29 40 -29 60 0 35 -39 71 -77 71 -31 0 -73 -42 -73 -73 0 -80
   58 -173 133 -214 30 -17 141 -18 1947 -18 1806 0 1917 1 1947 18 18 9 48 33
   67 53 71 73 66 -92 64 2149 -3 1946 -4 2021 -22 2055 -25 48 -762 786 -811
   813 l-40 22 -1545 2 c-1252 2 -1552 0 -1581 -11z m3476 -516 l350 -350 -353 0
   -352 0 0 350 c0 193 1 350 3 350 1 0 160 -157 352 -350z m-2574 -2668 l1296
   -2 24 -26 24 -26 0 -595 0 -595 -28 -24 -28 -24 -1273 0 -1273 0 -43 23 c-30
   16 -51 37 -67 67 l-23 43 0 603 0 603 48 -22 47 -22 1296 -3z"/>
   <path d="M902 1647 c-22 -23 -22 -28 -22 -371 l0 -347 26 -26 c20 -20 31 -24
   57 -19 57 11 67 34 67 161 l0 113 88 4 c104 5 151 26 209 92 114 130 69 324
   -92 397 -32 15 -66 19 -176 19 -129 0 -137 -1 -157 -23z m287 -141 c57 -30 68
   -107 22 -158 -27 -30 -34 -33 -105 -36 l-76 -4 0 106 0 106 65 0 c38 0 78 -6
   94 -14z"/>
   <path d="M2315 1645 l-25 -24 0 -343 c1 -367 1 -367 51 -387 30 -11 83 8 93
   34 3 9 6 77 6 151 l0 134 95 0 c90 0 98 2 122 26 20 20 24 31 19 57 -11 54
   -26 62 -136 65 l-100 4 0 79 0 79 105 0 c99 0 107 2 130 25 14 13 25 36 25 50
   0 14 -11 37 -25 50 -24 24 -28 25 -180 25 -152 0 -156 -1 -180 -25z"/>
   <path d="M1574 1639 c-18 -20 -19 -41 -19 -361 l0 -340 29 -25 c29 -25 32 -25
   160 -21 162 5 212 24 285 104 75 81 95 143 95 284 0 143 -22 208 -97 287 -73
   78 -122 93 -296 93 -128 0 -139 -2 -157 -21z m317 -152 c50 -34 81 -97 87
   -177 7 -99 -12 -163 -62 -215 -44 -46 -88 -60 -171 -53 l-40 3 -3 233 -2 232
   78 0 c66 0 85 -4 113 -23z"/>
   <path d="M2638 4290 c-51 -15 -94 -49 -118 -92 -21 -36 -25 -56 -25 -123 1
   -98 27 -188 98 -338 l50 -108 -17 -57 c-33 -105 -146 -383 -207 -506 l-61
   -122 -86 -29 c-132 -45 -289 -127 -356 -187 -100 -89 -130 -189 -83 -278 33
   -63 86 -102 146 -108 125 -12 267 115 427 383 55 93 57 94 110 110 140 41 413
   92 581 110 l62 7 72 -63 c194 -166 342 -221 456 -170 75 34 133 142 118 220
   -10 52 -77 115 -145 135 -85 26 -173 36 -316 36 l-131 0 -60 68 c-159 180
   -348 451 -340 487 3 11 17 70 31 130 35 146 44 301 23 375 -31 105 -121 153
   -229 120z m86 -156 c10 -26 6 -145 -8 -214 l-12 -65 -17 39 c-24 55 -47 147
   -47 189 0 26 6 40 22 51 29 20 54 20 62 0z m113 -800 c34 -49 92 -124 128
   -166 60 -72 63 -78 42 -82 -12 -3 -69 -12 -127 -21 -58 -9 -154 -26 -213 -39
   -70 -15 -107 -20 -103 -12 2 6 33 79 69 161 36 83 75 181 88 218 28 78 15 84
   116 -59z m692 -385 c35 -5 79 -16 98 -24 34 -14 34 -14 18 -45 -13 -24 -22
   -30 -49 -30 -36 0 -134 46 -181 84 l-30 25 40 1 c22 0 69 -5 104 -11z m-1331
   -271 c-57 -82 -151 -176 -184 -184 -30 -8 -54 14 -54 50 0 31 79 98 176 149
   49 25 93 46 97 47 5 0 -11 -28 -35 -62z"/>
   </g>
   </svg>

  )
}
<style>

</style>
