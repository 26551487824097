import clsx from 'clsx';
import React, { useEffect, useState } from 'react'
import { Button, Modal, RadialProgress, Textarea, Toggle } from 'react-daisyui'
import { uploadDocumento } from '../services/api';

export default function UploadDocumentsPopup({ open, onClose, onConfirm, client, files, validated = true, documentTypes = [] }) {
    const [loading, setLoading] = useState(false)
    const [documentType, setDocumentType] = useState('0')
    const [progress, setProgress] = useState(0)
    const [categorize, setCategorize] = useState(false)
    const [notify, setNotify] = useState(true)
    const [description, setDescription] = useState('')
    useEffect(() => {
        if (!validated && open) {
            upload(true)
        }
    }, [open])
    const upload = async stay => {
        if (!loading) {
            const uploadedDocuments = [];
            setProgress(0);
            setLoading(true);
            const totalBytes = Array.from(files).reduce((previous, current) => previous + current.size, 0);
            let currentBytes = 0;
            const onProgress = progressEvent => {
                const bytes = currentBytes + progressEvent.loaded;
                setProgress(totalBytes > 0 ? Math.min(100, Math.round((bytes * 100) / totalBytes)) : 0)
            }

            let count = 0;

            for (const file of files) {
                const document = await uploadDocumento(client.id, documentType, file, description, notify && count === 0, [], '', onProgress);
                uploadedDocuments.push(document);
                currentBytes += file.size;
                count++;
            }

            setProgress(100);
            setTimeout(() => {
                onConfirm(stay, uploadedDocuments);
                setLoading(false);
            }, 100);
        }
    }
    const close = () => {
        if (!loading) {
            onClose();
        }
    }
    const toggleCategorized = () => {
        if (categorize) {
            setDocumentType('0')
        }

        setCategorize(!categorize)
    }

    return (
        <Modal open={open} onClickBackdrop={close}>
            <Modal.Header>
                { Array.from(files).map((file, i) => (
                    <span key={i} className='font-bold text-xl'>{file.name}<br /></span>
                ))}

            </Modal.Header>

            <Modal.Body>
                <div className={clsx({ hidden: loading })}>
                    <div className="flex items-center mb-8">
                        <Toggle color="primary" onChange={toggleCategorized} checked={categorize} />
                        <span className="ml-3">Seleccionar un tipo de documento y validar</span>
                    </div>
                    { categorize ? (
                        <div>
                            <p>Indica el tipo de documento</p>
                            <select onChange={e => setDocumentType(e.target.value)} className="select select-bordered w-full mt-4 mb-10">
                                <option value="0">-- Selecciona --</option>

                                { documentTypes.map(type => (
                                    <option key={type.id} value={type.id}>{type.name}</option>
                                ))}

                            </select>
                            <p>Observaciones (opcional)</p>
                            <Textarea
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                className="w-full h-28 mb-10 mt-4" bordered></Textarea>
                        </div>
                    ) : ''}
                    <div className="flex items-center mb-8">
                        <Toggle color="primary" onChange={() => setNotify(!notify)} checked={notify} />
                        <span className="ml-3">Notificar al gestor jurídico</span>
                    </div>
                </div>
                <div className={clsx({ hidden: !loading }, 'flex justify-center pt-6 pb-10')}>
                    <RadialProgress size="10rem" thickness="1rem" value={progress}><span className="text-3xl">{progress}%</span></RadialProgress>
                </div>
            </Modal.Body>

            <Modal.Actions className={clsx({ hidden: loading })} >
                <Button color="ghost" onClick={close}>Cancelar</Button>
                <Button variant="outline" disabled={categorize && documentType === '0'} onClick={() => upload(true)}>
                    Guardar y subir más
                </Button>
                <Button disabled={categorize && documentType === '0'} onClick={() => upload(false)}>
                    Guardar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
