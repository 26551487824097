import moment from "moment";
import 'moment/locale/es';

export function listado(data) {
    if (!data.documentos) {
        return [];
    }

    return data.documentos.filter((documento) => documento.validated).sort(orderarPorFechaCreacion);
}

export function sinValidar(data) {
    if (!data.documentos) {
        return [];
    }

    return data.documentos.filter((documento) => !documento.validated);
}

export function soloPdf(documentos) {
    return documentos.filter((document) => document.filename.split('.').pop().toLowerCase() === 'pdf');
}

function orderarPorFechaCreacion(x, y) {
    if (x.created_at > y.created_at) {
        return -1;
    }

    if (x.created_at < y.created_at) {
        return 1;
    }

    return 0;
}

export function agruparPorFecha(documentos) {
    moment.locale('es');
    const docs = documentos.sort(orderarPorFechaCreacion)
    const result = {};

    for (const doc of docs) {
        const days = moment().diff(moment(doc.created_at), 'days');
        let date = 'hoy';

        if (days > 0) {
            date = days > 1 ? moment(moment(doc.created_at).add(1, 'days').format('YYYY-MM-DD')).fromNow() : 'ayer';
        }

        if (!result.hasOwnProperty(date)) {
            result[date] = [];
        }

        result[date].push(doc);
    }

    return result;
}

export function eliminarAgrupadoPorId(grouped, id) {
    for (const key in grouped) {
        if (Object.hasOwnProperty.call(grouped, key)) {
            const index = grouped[key].findIndex(doc => doc.id === id);

            if (index > -1) {
                grouped[key].splice(index, 1);
            }
        }
    }

    return grouped;
}

export function deagrupar(obj) {
    let res = [];

    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            res = [...res, ...obj[key]]
        }
    }

    return res;
}
