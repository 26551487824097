import { getMaxFileSize } from "../config";

export function validateFiles(files, extensions = []) {
    let valid = true;
    let errorMessage = '';

    for (const file of files) {
        if (!file) {
            errorMessage = `No se ha podido procesar el fichero ${file.name}`;
        }
        else if (extensions.indexOf('.' + file.name.split('.').pop().toLowerCase()) < 0) {
            errorMessage = 'El fichero ' + file.name + ' no tiene el formato correcto';
        }
        else if (file.size <= 0 || file.size > getMaxFileSize() * 1024) {
            errorMessage = 'El fichero ' + file.name + ' supera el tamaño máximo permitido';
        }
    }

    if (errorMessage !== '') {
        valid = false;
    }

    return [valid, errorMessage];
}
