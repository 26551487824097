import Folder from '../icons/Folder';
import User from '../icons/User';
import React from 'react';
import { Link } from 'react-router-dom';

export default function BreadCrumbs({ client, items }) {
    return (
        <div className="pb-5">
            <div className="text-sm breadcrumbs">
                <ul>
                    <li>
                        <Link to={`/documentacion/${client ? client.id : 0}`}>
                            <Folder />
                            Documentación
                        </Link>
                    </li>
                    {items && items.length > 0 ? items.map((item, i) => (
                        <li key={i}>
                            { !!item.url ? (
                                <Link to={item.url}>
                                    {item.icon ? item.icon : ''}
                                    {item.label}
                                </Link>
                            ) : (
                                <a href="/" style={{pointerEvents: 'none'}}>
                                    {item.icon ? item.icon : ''}
                                    {item.label}
                                </a>
                            ) }
                        </li>
                    )) : ''}
                </ul>
            </div>
        </div>
    );
}
