import React from 'react'
import { Button, Modal } from 'react-daisyui'

export default function HelpPopup({ open, onClose, children }) {

    return (
        <Modal open={open} onClickBackdrop={onClose}>
            <Modal.Body>
                { children }
            </Modal.Body>

            <Modal.Actions>
                <Button onClick={onClose}>
                    Entendido
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
