import clsx from 'clsx';
import { degrees } from 'pdf-lib';
import React, { useEffect, useState } from 'react'
import { Button, Modal, RadialProgress, Textarea } from 'react-daisyui'
import { uploadDocumento } from '../services/api';

export default function SaveDocumentPopup({ open, onClose, onConfirm, clientId, pdfDoc, filename = '', type = 0, initialDescription = '', pages = [], documentIds = [], documentTypes = [] }) {
    const [loading, setLoading] = useState(false)
    const [documentType, setDocumentType] = useState(0)
    const [documentName, setDocumentName] = useState('')
    const [progress, setProgress] = useState(0)
    const [description, setDescription] = useState('')
    useEffect(() => {
      setDocumentType(type)
    }, [type])
    useEffect(() => {
        setDescription(initialDescription)
    }, [initialDescription])
    useEffect(() => {
        setDocumentName(filename)
    }, [filename])
    const upload = async () => {
        if (!loading) {
            setProgress(0);
            setLoading(true);
            const extension = documentName.split('.').pop();
            let filename = documentName

            if (extension.toLowerCase() !== 'pdf') {
                filename += '.pdf'
            }

            // Upload
            const file = new Blob([await generateFile()], { type: 'application/pdf' })
            const totalBytes = file.size;
            let currentBytes = 0;
            const onProgress = progressEvent => {
                const bytes = currentBytes + progressEvent.loaded;
                setProgress(totalBytes > 0 ? Math.min(100, Math.round((bytes * 100) / totalBytes)) : 0)
            }

            await uploadDocumento(clientId, documentType, file, description, false, documentIds, filename, onProgress);
            setProgress(100);
            setTimeout(() => {
                onConfirm();
                setLoading(false);
            }, 100);
        }
    }
    const close = () => {
        if (!loading) {
            onClose();
        }
    }
    const generateFile = async () => {
        // Reordenar
        const newOrder = pages.map(p => p.index);
        let docPages = pdfDoc.getPages();

        for (let currentPage = 0; currentPage < newOrder.length; currentPage++) {
            pdfDoc.removePage(currentPage);
            pdfDoc.insertPage(currentPage, docPages[newOrder[currentPage]]);
        }

        pdfDoc.flush();
        // rotar
        docPages = pdfDoc.getPages();

        for (let index = 0; index < pages.length; index++) {
            if (!pages[index].removed) {
                docPages[index].setRotation(degrees(pages[index].internalRotation));
            }
        }

        pdfDoc.flush();
        // Mover eliminados al inicio
        let removed = 0;

        for (let currentPage = 0; currentPage < newOrder.length; currentPage++) {
            if (pages[currentPage].removed) {
                pdfDoc.removePage(currentPage);
                pdfDoc.insertPage(0, docPages[currentPage]);
                removed++;
            }
        }
        // Eliminar los primeros
        for (let index = 0; index < removed; index++) {
            pdfDoc.removePage(0)
        }

        return pdfDoc.save()
    }

    return (
        <Modal open={open} onClickBackdrop={close}>
            <Modal.Header>Guardar documento</Modal.Header>

            <Modal.Body>
                <div className={clsx({ hidden: loading })}>
                    <p>Nombre del documento</p>
                    <input type="text" onChange={e => setDocumentName(e.target.value)} value={documentName} className="input input-bordered w-full mt-4 mb-10" />
                    <p>Indica el tipo de documento</p>
                    <select onChange={e => setDocumentType(parseInt(e.target.value))} value={documentType} className="select select-bordered w-full mt-4 mb-10">
                        <option value="0">-- Selecciona --</option>

                        { documentTypes.map(type => (
                            <option key={type.id} value={type.id}>{type.name}</option>
                        ))}

                    </select>
                    <p>Observaciones (opcional)</p>
                    <Textarea
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        className="w-full h-28 mb-10 mt-4" bordered></Textarea>
                </div>
                <div className={clsx({ hidden: !loading }, 'flex justify-center pt-6 pb-10')}>
                    <RadialProgress size="10rem" thickness="1rem" value={progress}><span className="text-3xl">{progress}%</span></RadialProgress>
                </div>
            </Modal.Body>

            <Modal.Actions className={clsx({ hidden: loading })}>
                <Button color="ghost" onClick={close}>Cancelar</Button>
                <Button disabled={documentType === 0 || documentName.trim() === ''} className={clsx({ loading: loading})} onClick={upload}>
                    Guardar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
