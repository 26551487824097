import React from 'react'

export default function MergeDocuments() {
  return (
      <svg className='w-12 h-12' viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 14V30C16 32.2091 17.7909 34 20 34H32M16 14V10C16 7.79086 17.7909 6 20 6H29.1716C29.702 6 30.2107 6.21071 30.5858 6.58579L39.4142 15.4142C39.7893 15.7893 40 16.298 40 16.8284V30C40 32.2091 38.2091 34 36 34H32M16 14H10C8.89543 14 8 14.8954 8 16V38C8 40.2091 9.79086 42 12 42H30C31.1046 42 32 41.1046 32 40V34" stroke="#570DF8" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

  )
}
