import React from 'react'

export default function Xls() {
  return (
      <svg className="w-6 h-6" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.3125 2.75093H12.375V0.688426C12.375 0.483551 12.2842 0.289676 12.1261 0.159051C11.9694 0.0284264 11.759 -0.0279486 11.561 0.0133014L0.561 2.0758C0.235125 2.1363 0 2.41955 0 2.75093V17.8759C0 18.2059 0.235125 18.4906 0.561 18.5511L11.561 20.6136C11.6022 20.6218 11.6449 20.6259 11.6875 20.6259C11.847 20.6259 12.0024 20.5709 12.1261 20.4678C12.2842 20.3372 12.375 20.1419 12.375 19.9384V17.8759H21.3125C21.692 17.8759 22 17.5679 22 17.1884V3.43843C22 3.05893 21.692 2.75093 21.3125 2.75093ZM9.4545 12.611C9.70475 12.8957 9.67587 13.3302 9.38987 13.5804C9.25925 13.6946 9.09837 13.7509 8.9375 13.7509C8.74637 13.7509 8.55662 13.6712 8.4205 13.5158L6.42125 11.2319L4.66812 13.4869C4.532 13.6602 4.3285 13.7509 4.125 13.7509C3.97787 13.7509 3.82937 13.7042 3.70287 13.6066C3.40313 13.3728 3.3495 12.9411 3.58187 12.6413L5.49862 10.1773L3.608 8.0158C3.35775 7.73118 3.38662 7.29668 3.67262 7.04643C3.95725 6.79618 4.39037 6.82368 4.64337 7.11105L6.35937 9.0718L8.39575 6.4538C8.6295 6.15543 9.06125 6.10043 9.361 6.33418C9.66075 6.56655 9.71437 6.9983 9.48062 7.29943L7.28062 10.1264L9.4545 12.611ZM20.625 16.5009H12.375V15.1259H14.4375C14.817 15.1259 15.125 14.8179 15.125 14.4384C15.125 14.0589 14.817 13.7509 14.4375 13.7509H12.375V12.3759H14.4375C14.817 12.3759 15.125 12.0679 15.125 11.6884C15.125 11.3089 14.817 11.0009 14.4375 11.0009H12.375V9.62593H14.4375C14.817 9.62593 15.125 9.31793 15.125 8.93843C15.125 8.55893 14.817 8.25093 14.4375 8.25093H12.375V6.87593H14.4375C14.817 6.87593 15.125 6.56793 15.125 6.18843C15.125 5.80893 14.817 5.50093 14.4375 5.50093H12.375V4.12593H20.625V16.5009Z" fill="#1F2937" fillOpacity="0.5" />
          <path d="M18.5627 5.50098H17.1877C16.8082 5.50098 16.5002 5.80898 16.5002 6.18848C16.5002 6.56798 16.8082 6.87598 17.1877 6.87598H18.5627C18.9422 6.87598 19.2502 6.56798 19.2502 6.18848C19.2502 5.80898 18.9422 5.50098 18.5627 5.50098Z" fill="#1F2937" fillOpacity="0.5" />
          <path d="M18.5627 8.25098H17.1877C16.8082 8.25098 16.5002 8.55898 16.5002 8.93848C16.5002 9.31798 16.8082 9.62598 17.1877 9.62598H18.5627C18.9422 9.62598 19.2502 9.31798 19.2502 8.93848C19.2502 8.55898 18.9422 8.25098 18.5627 8.25098Z" fill="#1F2937" fillOpacity="0.5" />
          <path d="M18.5627 11.001H17.1877C16.8082 11.001 16.5002 11.309 16.5002 11.6885C16.5002 12.068 16.8082 12.376 17.1877 12.376H18.5627C18.9422 12.376 19.2502 12.068 19.2502 11.6885C19.2502 11.309 18.9422 11.001 18.5627 11.001Z" fill="#1F2937" fillOpacity="0.5" />
          <path d="M18.5627 13.7507H17.1877C16.8082 13.7507 16.5002 14.0587 16.5002 14.4382C16.5002 14.8177 16.8082 15.1257 17.1877 15.1257H18.5627C18.9422 15.1257 19.2502 14.8177 19.2502 14.4382C19.2502 14.0587 18.9422 13.7507 18.5627 13.7507Z" fill="#1F2937" fillOpacity="0.5" />
      </svg>
  )
}
